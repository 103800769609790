// import textData from '@/static/textData.js' // 合作案例


const Home = {
  tabList: [
    {
      id: 1,
      tabTitle: '剧本杀解密',
      img: 'https://h5.ophyer.cn/official_website/other/home-tab-img01-1.png',
      lunTitle: '更沉浸的剧本杀游戏体验',
      tip: '',
      text: [
        '• 以景区历史文化为背景，为景区量身定制游览剧本',
        '• 提供多重角色，满足游客个性化选择',
        '• 每个打卡点都配置推动情节的线索，提升游客主动性',
        '• 通过互动小游戏或商家真人互动发放剧情线索，互动性更强',
        '• 到达商户打卡点发放优惠券促进二销',
      ]
    },
    {
      id: 2,
      tabTitle: '寻宝集碎片',
      img: 'https://h5.ophyer.cn/official_website/other/home-tab-img02-1.png',
      lunTitle: '轻快的游戏流程，让玩家快速了解每一个打卡点',
      tip: '',
      text: [
        '• 以景区历史文化为背景，为景区量身定制寻宝游戏',
        '• 每个打卡点均可获得一个宝武碎片',
        '• 碎片可以合成历史文化画卷，展示景区的历史文化或风土人情',
        '• 完成碎片收集即可获得商家发放的优惠券，促进二销',
      ]
    }
  ],

  warp2: [
    { 
      id:1, 
      src: require('@/assets/images/icon/vrPanorams-solution-warp3-img1.png'), 
      tit:'AR地图导航',
      content:'基于云计算打造的跨平台室内外AR导航解决方案，满足景区、智慧园区等场景的实景导航需求'
    },
    { 
      id:2,  
      src: require('@/assets/images/icon/vrPanorams-solution-warp3-img2.png'), 
      tit:'AR全息导游' ,
      content:'帮助游客多感官更直接的了解景点的历史文化；同时对进美食、特产、文创等周边产品进行种草'
    },
    { 
      id:3,  
      src: require('@/assets/images/icon/vrPanorams-solution-warp3-img3.png'), 
      tit:'AR虚拟导游',
      content:'根据景区特色量身定制虚拟导游，同时打造虚拟IP，形成独具特色的景区文化资产' 
    },
    { 
      id:4,  
      src: require('@/assets/images/icon/vrPanorams-solution-warp3-img4.png'), 
      tit:'AR历史重现',
      content:'根据景区特有的历史故事，对应的场景下对历史故事进行重新复原与演绎' 
    },
    { 
      id:5,  
      src: require('@/assets/images/icon/vrPanorams-solution-warp3-img5.png'), 
      tit:'景区场景复原',
      content:'针对景区未修复的场景，利用AR定点技术进行场景扫描修复' 
    },
    { 
      id:6,  
      src: require('@/assets/images/icon/vrPanorams-solution-warp3-img6.png'), 
      tit:'AR互动留言墙',
      content:'利用LBS地理位置识别技术，开设互动留言墙；发表此时此刻的留言感想' 
    },
  ],

  warp3: [
    { 
      id:1, 
      src: require('@/assets/images/icon/arPunch-home-warp3-img1.png'), 
      tit:'强内容联动',
      content:'基于年轻人最喜爱的剧本及寻宝游戏进行AR定制开发，激发游客好奇心的同时，刺激游客消费'
    },
    { 
      id:2,  
      src: require('@/assets/images/icon/arPunch-home-warp3-img2.png'), 
      tit:'使用门槛低' ,
      content:'基于小程序开发，扫码即用；依托微信亿级装机率，快速裂变精准触达'
    },
    { 
      id:3,  
      src: require('@/assets/images/icon/arPunch-home-warp3-img3.png'), 
      tit:'体验效果好',
      content:'通过SLAM算法和WebGL渲染引擎生成高质量AR内容；即扫即用，零门槛，适配所有机型' 
    },
    { 
      id:4,  
      src: require('@/assets/images/icon/arPunch-home-warp3-img4.png'), 
      tit:'开发灵活',
      content:'可根据客户需求量身定制活动方案，选择适合自己景区的功能及定制化开发' 
    },

  ],

  warp4: [
    { 
      id:1, 
      src: 'https://h5.ophyer.cn/official_website/other/arPunch-home-warp4-img1.png', 
      tit:'自然景区'
    },
    { id:2,  
      src: 'https://h5.ophyer.cn/official_website/other/arPunch-home-warp4-img2.png', 
      tit:'名胜古迹'
    },
    { id:3,  
      src: 'https://h5.ophyer.cn/official_website/other/arPunch-home-warp4-img3.png', 
      tit:'博物馆'
    },
    { id:4,  
      src: 'https://h5.ophyer.cn/official_website/other/arPunch-home-warp4-img4.png', 
      tit:'节日活动'
    },
  ],
}


const Crm = {
  tabList: [
    {
      id: 1,
      tabTitle: '消息发送',
      img: 'https://h5.ophyer.cn/official_website/other/assistantAccount-crm-tabList-img1.png',
      lunTitle: '采集消息，实现标签化管理',
      tip: '',
      text: [
        '通过采集粉丝发送给公众号的所有消息，并转换为标签存储',
      ]
    },
    {
      id: 2,
      tabTitle: '关注来源',
      img: 'https://h5.ophyer.cn/official_website/other/assistantAccount-crm-tabList-img2.png',
      lunTitle: '大数据分层管理数据来源',
      tip: '',
      text: [
        '搜集用户数据来源，进行大数据分析，自动化分层管理',
      ]
    },
    {
      id: 3,
      tabTitle: '跨平台',
      img: 'https://h5.ophyer.cn/official_website/other/assistantAccount-crm-tabList-img3.png',
      lunTitle: 'H5、小程序数据互通',
      tip: '',
      text: [
        '通过接口同步获取粉丝在小程序的行为，与小程序联动',
      ]
    },
    {
      id: 4,
      tabTitle: '事件互动',
      img: 'https://h5.ophyer.cn/official_website/other/assistantAccount-crm-tabList-img4.png',
      lunTitle: '用户行为互动',
      tip: '',
      text: [
        '采集粉丝在H5页面的行为，获取阅读喜好，确认是否有访问和分享动作',
      ]
    },
    {
      id: 5,
      tabTitle: 'H5页面',
      img: 'https://h5.ophyer.cn/official_website/other/assistantAccount-crm-tabList-img5.png',
      lunTitle: '行为数据收集',
      tip: '',
      text: [
        '采集粉丝在H5页面的行为，获取阅读喜好，确认是否有访问和分享动作',
      ]
    }
  ],

  warp2: [
    { 
      id:1, 
      src: require('@/assets/images/icon/assistantAccount-crm-warp2-img1.png'), 
      tit:'多维度筛选粉丝数据',
    },
    { 
      id:2,  
      src: require('@/assets/images/icon/assistantAccount-crm-warp2-img2.png'), 
      tit:'支持手动修改用户标签' ,
    },
    { 
      id:3,  
      src: require('@/assets/images/icon/assistantAccount-crm-warp2-img3.png'), 
      tit:'支持手动批量增减标签',
    },
    { 
      id:4,  
      src: require('@/assets/images/icon/assistantAccount-crm-warp2-img4.png'), 
      tit:'延时推送消息',
    },
    { 
      id:5,  
      src: require('@/assets/images/icon/assistantAccount-crm-warp2-img5.png'), 
      tit:'定向群发',
    },
    { 
      id:6,  
      src: require('@/assets/images/icon/assistantAccount-crm-warp2-img6.png'), 
      tit:'三方API调用',
    },
    { 
      id:7,  
      src: require('@/assets/images/icon/assistantAccount-crm-warp2-img7.png'), 
      tit:'标记粉丝关键信息',
    },
    { 
      id:8,  
      src: require('@/assets/images/icon/assistantAccount-crm-warp2-img8.png'), 
      tit:'个性化菜单设置',
    }
  ],

  warp3: [
    { 
      id:1, 
      src: require('@/assets/images/icon/arPunch-home-warp3-img1.png'), 
      tit:'强内容联动',
      content:'基于年轻人最喜爱的剧本及寻宝游戏进行AR定制开发，激发游客好奇心的同时，刺激游客消费'
    },
    { 
      id:2,  
      src: require('@/assets/images/icon/arPunch-home-warp3-img2.png'), 
      tit:'使用门槛低' ,
      content:'基于小程序开发，扫码即用；依托微信亿级装机率，快速裂变精准触达'
    },
    { 
      id:3,  
      src: require('@/assets/images/icon/arPunch-home-warp3-img3.png'), 
      tit:'体验效果好',
      content:'通过SLAM算法和WebGL渲染引擎生成高质量AR内容；即扫即用，零门槛，适配所有机型' 
    },
    { 
      id:4,  
      src: require('@/assets/images/icon/arPunch-home-warp3-img4.png'), 
      tit:'开发灵活',
      content:'可根据客户需求量身定制活动方案，选择适合自己景区的功能及定制化开发' 
    },

  ],

  warp4: [
    { 
      id:1, 
      src: 'https://h5.ophyer.cn/official_website/other/arPunch-home-warp4-img1.png', 
      tit:'自然景区'
    },
    { id:2,  
      src: 'https://h5.ophyer.cn/official_website/other/arPunch-home-warp4-img2.png', 
      tit:'名胜古迹'
    },
    { id:3,  
      src: 'https://h5.ophyer.cn/official_website/other/arPunch-home-warp4-img3.png', 
      tit:'博物馆'
    },
    { id:4,  
      src: 'https://h5.ophyer.cn/official_website/other/arPunch-home-warp4-img4.png', 
      tit:'节日活动'
    },
  ],
}





export default {
  Home,
  Crm
}