<template>
  <v-app>
    <v-banner
        single-line
        height="400"
        class="children-banner"
    >
      <v-carousel height="100%"
                  class="carousel-new carousel-banner"
                  hide-delimiter-background
                  hide-delimiters
                  :show-arrows="false">
        <v-carousel-item v-for="(banner,index) in bannerList" :key="index">
          <v-img
              :src="banner.img"
              height="100%"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    size="80"
                    width="8"
                    color="grey lighten-3"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container color="transparent" class="pa-0 max-width-1200 height-per-100 d-flex align-center justify-center">
              <v-card color="transparent" class="ml-0" flat>
                <v-card-title class="pa-0 font-size-banner-title family-Bold justify-center" :style="'color: ' + banner.titleColor + '!important;'">
                  <div>
                    <div v-for="(title,tipIndex) in banner.title" :key="tipIndex" class="pt-10" style="font-weight: 500">{{title}}</div>
                  </div>
                </v-card-title>
                <v-card-title class="pa-0 font-size-banner-tip mt-8" :style="'color: ' + banner.titleColor + '!important;'">
                  <div>
                    <div v-for="(tip,tipIndex) in banner.tip" :key="tipIndex" class="mb-3">{{tip}}</div>
                  </div>
                </v-card-title>
                <v-card-actions class="pa-0 mt-10 justify-center" v-if="banner.btn">
                  <v-btn
                      rounded
                      color="#0568FD"
                      width="160"
                      height="50"
                      class="font-size-20"
                      @click.stop="handleBannerBtn(banner.router)"
                  >
                    <span class="color-FFF">{{ banner.btn }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-banner>

    <!-- 应用场景 -->
    <div class="d-flex justify-center width-per-100 warps warp1" style="padding-bottom: 70px;">
      <v-card flat class="max-width-1200 width-per-100" color="transparent">
        <div class="public-title">
          <p>DATA SCRAPING</p>
          <div>
            <span>数据抓取 不错过任意一条数据</span>
          </div>
        </div>
        <v-tabs v-model="tab" centered  color="#0084ff" class="g-tabs">
          <v-tab v-for="(v, k) in data.tabList" :key="k">
            <b>{{ v.tabTitle }}</b>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(v, k) in data.tabList" :key="k" :transition="false">
              <div class="box">
                <transition name="slide-fade" appear>
                  <div class="left" v-show="tab == k"><img :src='v.img' /></div>
                </transition>
                <transition name="slide-fade-reverse" appear>
                  <div class="right" v-show="tab == k">
                    <v-card-text class="title" v-text="v.lunTitle" />
                    <v-card-text class="tip" v-text="v.tip" />
                    <v-card-text class="text" v-for="(i, j) in v.text" :key="j" v-text="i" />
                  </div>
                </transition>
              </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>

    <!-- 产品插件 -->
    <v-card class="warps warp2" :flat="true">
      <div class="public-title">
        <p>DATA MINING</p>
        <div>
          <span>数据挖掘 不忽略任意一点价值</span>
        </div>
      </div>
      <v-container>
        <v-row class="max-width-1200 " style="margin:0 auto" >
          <v-col cols="3" v-for="n in data.warp2" :key="n.id" >
            <v-card flat>
              <div class="img_title" justify="center">
                <img :src="n.src" />
                <h2>{{n.tit}}</h2>
                <p v-html="n.content">
                  {{n.content}}
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- 产品插件 -->
    <v-card class="warps warp3" :flat="true">
      <div class="public-title">
        <p>DATA CONNECTION</p>
        <div>
          <span>数据打通 Union ID</span>
          <span>无论微信公众号、小程序、H5还是是用微信登录的网站以及APP，均可用Union ID完成打通和串联</span>
        </div>
      </div>
      <div class="con">
        <img src="https://h5.ophyer.cn/official_website/other/assistantAccount-crm-warp3-img.png" alt="" />
      </div>
    </v-card>


  </v-app>
</template>

<script>
import textData from './textData.js';
import qs from 'qs'

export default {
  name: 'Crm',
  components: {
  },
  data() {
    return {
      data: textData.Crm,
      tab: null,
      bannerList: [
        {
          img: "https://h5.ophyer.cn/official_website/banner/assistantAccount-crm-banner.png",
          title: ["微信CRM助手"],
          titleColor:"#FFFFFF",
          tip: ["从认识粉丝到了解粉丝，帮你一站式解决所有问题"],
        },
      ],

      showDialog: false
    }
  },
  created() {
  },
  methods: {
    handleBannerBtn(router) {
      if(router){
        window.open(window.location.protocol + "//" + window.location.host + router);
      }else {
        this.showDialog = true;
      }
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog{
  z-index: 3;
  width: 550px;
  height: 380px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
  .close-btn{
    .icon {
      cursor: pointer;
      top: 10px;
      left: 480px;
    }
  }
  ::v-deep .v-card--link {
    cursor: unset;
  }
}


.warps {
  margin: 0 auto;
  text-align: center;
  width: 1200px !important;
}


.warp1 {
  .public-title {
    margin-top: 40px;
    margin-bottom: -10px;
  }
  .g-tabs {
    margin-top: 20px;
  }
  .box {
    width: 1000px;
    margin: 40px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
    }
  }
  .right{
    text-align: start;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-size: 24px !important;
      font-weight: 600 !important;
      margin-bottom: -20px;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 16px !important;
    }
    .text {
      font-size: 14px !important;
      line-height: 26px;
      color: #666;
    }
  }
}

.warp2 {
  margin-top: -40px;
  .public-title {
    margin-bottom: -15px;
  }
  .img_title {
    width: 266px;
    margin: 0 auto;
    text-align: center;
    img {
      margin: 0 auto;
      width: 120px;
      height: 120px;
    }
    h2 {
      margin-top: 10px;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666;
      margin-top: 10px;
      line-height: 20px;
    }
  }
}

.warp3 {
  margin-bottom: 60px;
  .img_title {
    width: 266px;
    margin: 15px auto;
    text-align: center;
    
    .con {
      height: 160px;
    }
  }
}


</style>
